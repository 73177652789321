import { createApp, h } from "vue";
import { createInertiaApp } from "@inertiajs/inertia-vue3";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";

import axios from "axios";
import VueAxios from "vue-axios";
import { setupAxios } from "./Plugins/axios";
import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";
import FontAwesomeIcon from "./Plugins/fontawesome";

import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";

import Datepicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";

import VCalendar from "v-calendar";
import "v-calendar/dist/style.css";

import Vue3EasyDataTable from "vue3-easy-data-table";
import "vue3-easy-data-table/dist/style.css";

import VueApexCharts from "vue3-apexcharts";
import Popper from "vue3-popper";

import { VueCookies } from "vue-cookies";

import store from "@/Store";

import { setOptions } from "filepond";
import pt_BR from "filepond/locale/pt-br.js";
setOptions(pt_BR);

createInertiaApp({
    progress: {
        // The color of the progress bar...
        color: "#79577a",

        // Whether to include the default NProgress styles...
        includeCSS: true,

        // Whether the NProgress spinner will be shown...
        // showSpinner: false,
    },
    resolve: (name) =>
        resolvePageComponent(
            `./Pages/${name}.vue`,
            import.meta.glob("./Pages/**/*.vue")
        ),
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(VueSweetalert2)
            .use(VueTippy)
            .use(VueCookies)
            .use(VueAxios, axios)
            .use(VCalendar, {})
            .use(VueApexCharts)
            .use(store)
            .component("font-awesome-icon", FontAwesomeIcon)
            .component("Datepicker", Datepicker)
            .component("EasyDataTable", Vue3EasyDataTable)
            .component("Popper", Popper);

        setupAxios(app);
        app.mount(el);
    },
});
