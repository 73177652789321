import axios from "axios";

function initialState() {
    return {
        origins: [],
    };
}

export default {
    namespaced: true,
    state: initialState,

    getters: {
        origins: (state) => state.origins,
    },

    mutations: {
        UPDATE_ORIGINS(state, val) {
            state.origins = val;
        },
    },

    actions: {
        listOrigins() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/OriginProvider")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
    },
};
