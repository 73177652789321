import axios from "axios";

function initialState() {
    return {
        templateList: [],
        subject: "",
        content: "",
        file: "",
        access: [],
        role: [],
        network: [],
        provider: [],
        person: [],
    };
}

export default {
    namespaced: true,
    state: initialState,

    getters: {
        templateList: (state) => state.templateList,
        subject: (state) => state.subject,
        content: (state) => state.content,
        file: (state) => state.file,
        access: (state) => state.access,
        role: (state) => state.role,
        network: (state) => state.network,
        provider: (state) => state.provider,
        contact: (state) => state.contact,
        person: (state) => state.person,
    },

    mutations: {
        UPDATE_TEMPLATE_LIST(state, val) {
            state.templateList = val;
        },
        UPDATE_SUBJECT(state, val) {
            state.subject = val;
        },
        UPDATE_CONTENT(state, val) {
            state.content = val;
        },
        UPDATE_FILE(state, val) {
            state.file = val;
        },
        UPDATE_ACCESS(state, val) {
            state.access = val;
        },
        UPDATE_ROLE(state, val) {
            state.role = val;
        },
        UPDATE_NETWORK(state, val) {
            state.network = val;
        },
        UPDATE_PROVIDER(state, val) {
            state.provider = val;
        },
        UPDATE_CONTACT(state, val) {
            state.contact = val;
        },
        UPDATE_PERSON(state, val) {
            state.person = val;
        },
    },

    actions: {
        listTemplates() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/Communication/Template/")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        saveTemplate(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Communication/Template/Create", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        updateTemplate(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Communication/Template/Update", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        listProviders() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/Provider/Fantasy/")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        listNetworks() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/Provider/Network")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        listAccess() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/Person/Access")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        listRole() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/Person/Role")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        listUsers(ctx, form) {
            const filters = {
                filters: form,
                page: 1,
                limit: 10,
            };

            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Communication/Contact/Search", filters)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        searchContactPerson(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Communication/Contact/Person/Search", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        sendEmail(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Communication/Email/Send", form, {
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "multipart/form-data",
                        },
                    })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
    },
};
