import axios from "axios";

function initialState() {
    return {
        infoId: 0,
        infoCnpj: "",
        list: [],
        statusOptions: [],
        statusGroups: [],
        kanbanStatusOptions: [],
    };
}

export default {
    namespaced: true,
    state: initialState,

    getters: {
        infoId: (state) => state.infoId,
        infoCnpj: (state) => state.infoCnpj,
        action: (state) => state.action,
    },

    mutations: {
        UPDATE_INFO_ID(state, val) {
            state.infoId = val;
        },
        UPDATE_INFO_CNPJ(state, val) {
            state.infoCnpj = val;
        },
        UPDATE_EDIT_ACTION(state, val) {
            state.action = val;
        },
    },

    actions: {
        saveRegister(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Providers/Register", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        updateRegister(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/RegisterProvider/Update", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        createDocs(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Provider/Clicksign/Contract", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        allProviders({ commit, state }) {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/RegisterProvider")
                    .then((response) => {
                        state.list = response.data;
                        resolve(response)
                    })
                    .catch((error) => reject(error.response));
            });
        },
        searchKanbanProviders({ commit, state }, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/RegisterProvider/KanbanSearch", form)
                    .then((response) => {
                        state.list = response.data;
                        resolve(response)
                    })
                    .catch((error) => {
                        reject(error.response)
                    });
            });
        },
        saveStatusProvider(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/RegisterProvider/AlterStatus", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        searchProviders(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/RegisterProvider/Search", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        listTables() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/RegisterProvider/Tables")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        listWhiteLabels() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/RegisterProvider/WhiteLabel")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        listTeams() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/RegisterProvider/Team")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        saveTeamProvider(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Provider/Network", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        organizeFiles(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Providers/OrganizeFiles", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        organizeFileDelete(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Providers/OrganizeFileDelete", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        pythonBv(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Providers/PythonBv", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        notification(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Provider/Clicksign/notification", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        searchIdBv(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post(window.location.origin + "/adm/api/acesso.php", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        providerSubmit(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Providers/Create", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        providerUserSubmit(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Person/Create", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        providerDelete(ctx, id) {
            return new Promise((resolve, reject) => {
                axios
                    .delete("/api/Provider/Delete/" + id)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        emailExists(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Person/ByEmail", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        sendEmailAll(ctx, form) {
            // ID, STATUS
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Person/sendEmailAll", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        webhookQiDent(ctx, form) {
            //UUID, CNPJ, STATUS
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/WebHook/QiDent", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        addPersonProvider(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Person/Provider/Add", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        searchCnpj(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post(window.location.origin + "/api/Consulta/Serpro", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        updateResponsible(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/RegisterProvider/Responsible/Update", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        sendEmailResponsible(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/Provider/Clicksign/sendEmailResponsible", form)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        allRejectedProviders() {
            return new Promise((resolve, reject) => {
                axios
                    .get("/api/RegisterProvider/rejected")
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        exportKanban(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/RegisterProvider/Kanban/Export", form, {
                        responseType: "arraybuffer",
                        headers: { "Content-Type": "blob" },
                    })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        exportKanbanLog(ctx, form) {
            return new Promise((resolve, reject) => {
                axios
                    .post("/api/RegisterProvider/Kanban/Log/Export", form, {
                        responseType: "arraybuffer",
                        headers: { "Content-Type": "blob" },
                    })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        togglePendingDocsFlag(ctx, { providerRegisterId }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/RegisterProvider/${providerRegisterId}/TogglePendingDocsFlag`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },
        toggleTrainingSentFlag(ctx, { providerRegisterId }) {
            return new Promise((resolve, reject) => {
                axios
                    .post(`/api/RegisterProvider/${providerRegisterId}/ToggleTrainingSentFlag`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error.response));
            });
        },

        getStatusOptionsSchema({ commit, state }, ctx) {
            return new Promise((resolve, reject) => {
                axios
                    .get(`/api/RegisterProvider/GetStatusOptionsSchema`)
                    .then((response) => {
                        state.statusGroups = response.data.statusGroups;
                        state.statusOptions = response.data.statusOptions;
                        state.kanbanStatusOptions = response.data.kanbanStatusOptions;
                        resolve(response)
                    })
                    .catch((error) => reject(error.response));
            });
        }
    },
};
