import axios from 'axios'

const axiosClient = axios.create({
    baseURL: window.location.origin + '/api'
})

// axiosClient.interceptors.request.use(config => {
//     config.headers.Authorization = `Bearer ${store.state.user.token}`
//     return config
// })

export function setupAxios(app) {
    app.provide("$http", axiosClient);
}

export default axiosClient